<template>
  <LiefengContent>
    <template v-slot:title>回访记录</template>
    <template v-slot:toolsbarRight>
      <Button @click="addRecord" type="primary">新增回访情况</Button>
      <Button type="warning" @click="drawerStatus = true"
        >查看服务对象信息</Button
      >
      <Button
        type="error"
        @click="$router.push('/archivesreturnvisit')"
        icon="ios-arrow-back"
        >返回</Button
      >
      <Drawer width="320" title="服务对象信息" v-model="drawerStatus">
        <div>档案编号：{{ serviceObj.archiveNum }}</div>
        <div>服务对象：{{ serviceObj.name }}</div>
        <div>所属社区：{{ serviceObj.orgName }}</div>
        <div>手机号：{{ serviceObj.mobile }}</div>
        <div>分类：{{ serviceObj.difficultType }}</div>
        <div>分级：{{ serviceObj.difficultLevel }}</div>
      </Drawer>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="page"
        @hadlePageSize="hadlePageSize"
        :pagesizeOpts="[20, 30, 50, 100]"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
      ></LiefengTable>

      <!-- 新增/修改 -->
      <LiefengModal
        :title="addChangeTitle"
        :value="addChangeStatus"
        height="500px"
        @input="addChangeStatusFn"
      >
        <template v-slot:contentarea>
          <Form
            ref="addChangeForm"
            :model="addChangeData"
            :rules="addChangeValidator"
            :label-colon="true"
            :label-width="100"
          >
            <FormItem label="回访人" prop="visitName">
              <Input
                v-model.trim="addChangeData.visitName"
                :maxlength="20"
              ></Input>
            </FormItem>
            <FormItem label="回访日期" prop="visitDate">
              <DatePicker
                type="date"
                v-model="addChangeData.visitDate"
                placeholder="请选择回访日期"
              ></DatePicker>
            </FormItem>
            <FormItem label="回访情况">
              <Input
                type="textarea"
                :rows="4"
                show-word-limit
                v-model="addChangeData.content"
                :maxlength="400"
                placeholder="请输入服务成效，不超过400字"
              ></Input>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button
            type="info"
            @click="
              cancelModelTip = true;
              addChangeStatusFn(false);
            "
            style="margin-right: 10px"
            >取消</Button
          >
          <Button type="primary" @click="saveAddChange">保存</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/returnvisitRecord')
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import LiefengContent from "@/components/LiefengContent";
import { format } from "@/utils/formatTime";
export default {
  data() {
    return {
      talbeColumns: [
        {
          title: "回访情况",
          key: "content",
          minWidth: 200,
          align: "center",
        },
        {
          title: "回访人",
          key: "visitName",
          minWidth: 150,
          align: "center",
        },
        {
          title: "回访时间",
          key: "visitDate",
          minWidth: 150,
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          width: 240,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "20px",
                  },
                  on: {
                    click: () => {
                      this.changeRecord(params.row.id);
                    },
                  },
                },
                "修改回访"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.delRow(params.row.id);
                    },
                  },
                },
                "删除回访记录"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      total: 0,
      page: 1,
      loading: false,
      pageSize: 20,

      //查看抽屉
      drawerStatus: false,
      serviceObj: {},

      //新增/修改
      cancelModelTip: true, //true代表关闭时弹窗提示
      addChangeStatus: false,
      addChangeIden: true,
      addChangeTitle: "",
      infoId: "",
      addChangeData: {},
      addChangeValidator: {
        visitName: [
          { required: true, message: "请输入回访人", trigger: "blur" },
        ],
        visitDate: [
          { required: true,type:"date", message: "请选择回访日期", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    //新增
    addRecord() {
      this.$refs.addChangeForm.resetFields();
      this.addChangeTitle = "新增回访情况";
      this.addChangeData = {
        visitName: parent.vue.loginInfo.userinfo.realName,
        visitDate: new Date()
      };
      this.addChangeStatus = true;
      this.addChangeIden = true;
    },
    //修改服务
    changeRecord(id) {
      this.$refs.addChangeForm.resetFields();
      this.addChangeIden = false;
      this.infoId = id;
      this.addChangeTitle = "修改回访情况";
      this.$Message.loading("正在加载数据...");
      this.addChangeData = {};
      this.$get("/archives/api/pc/referralVisit/queryById", {
        id,
      })
        .then((res) => {
          this.$Message.destroy();
          if (res.code == 200 && res.data) {
            this.addChangeStatus = true;
            this.addChangeData = {
              content: res.data.content,
              visitDate: format(res.data.visitDate,"yyyy-MM-dd"),
              visitName: res.data.visitName,

            };
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.error({
            background: true,
            content: "获取数据失败",
          });
        });
    },
    saveAddChange() {
      this.$refs.addChangeForm.validate((status) => {
        if (status) {
          let url = "";
          let params = {};
           if (this.addChangeData.visitDate) {
              this.addChangeData.visitDate = format(this.addChangeData.visitDate, "yyyy-MM-dd");
            }
          if (this.addChangeIden) {
            url = "/archives/api/pc/referralVisit/add";
            params = {
              ...this.addChangeData,
              archiveId: this.$route.query.id,
              staffName: parent.vue.loginInfo.userinfo.realName,
            };
          } else {
            url = "/archives/api/pc/referralVisit/update";
            params = {
              ...this.addChangeData,
              staffName: parent.vue.loginInfo.userinfo.realName,
              id: this.infoId,
            };
          }
          this.$post(url, params)
            .then((res) => {
              if (res.code == 200) {
                this.cancelModelTip = false;
                this.addChangeStatus = false;
                this.$Message.success({
                  background: true,
                  content: "保存成功"
                })
                this.hadlePageSize({
                  page: this.page,
                  pageSize: this.pageSize,
                });
              } else {
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            })
            .catch((err) => {
              this.$Message.error({
                background: true,
                content: "保存失败",
              });
            });
        }
      });
    },
    addChangeStatusFn(status) {
      if (!status && this.cancelModelTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.addChangeStatus = status;
          },
        });
      } else {
        this.addChangeStatus = status;
      }
    },
    //删除记录
    delRow(id) {
       this.$Modal.confirm({
                    title: '温馨提示',
                    content: '<p>您正在删除此条记录，是否继续？</p>',
                     onOk: () => {
                        this.$post('/archives/api/pc/referralVisit/delete',{
                          id
                        }).then(res => {
                          if(res.code == 200) {
                            this.$Message.success({
                              background: true,
                              content: "删除成功"
                            })
                            this.hadlePageSize({
                              page: this.page,
                              pageSize: this.pageSize
                            })
                          }else {
                            this.$Message.error({
                              background: true,
                              content: res.desc
                            })
                          }
                        })
                    },
                });
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$post("/archives/api/pc/referralVisit/queryVisitByPage", {
        page: obj.page,
        pageSize: obj.pageSize,
        archiveId: this.$route.query.id,
      }).then((res) => {
        if (res.code == 200) {
          this.serviceObj = {
            name: res.data.name,
            mobile: res.data.mobile,
            archiveNum: res.data.archiveNum,
            difficultType: res.data.difficultType,
            orgName: res.data.orgName,
            difficultLevel: res.data.difficultLevel,
          };
          switch (res.data.difficultType) {
            case "1":
              this.serviceObj.difficultType = "低保低收入对象";
              break;
            case "2":
              this.serviceObj.difficultType = "特困人员";
              break;
            case "3":
              this.serviceObj.difficultType = "留守老年人";
              break;
            case "4":
              this.serviceObj.difficultType = "独居孤寡老人";
              break;
            case "5":
              this.serviceObj.difficultType = "困境儿童";
              break;
            case "6":
              this.serviceObj.difficultType = "贫困重度残疾人";
              break;
            default:
              this.serviceObj.difficultType = "其他";
              break;
          }
          switch (res.data.difficultLevel) {
            case "1":
              this.serviceObj.difficultLevel = "一级";
              break;
            case "2":
              this.serviceObj.difficultLevel = "二级";
              break;
            case "3":
              this.serviceObj.difficultLevel = "三级";
              break;
            case "4":
              this.serviceObj.difficultLevel = "四级";
              break;
          }
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
        if (
          res.data.referralVisitPages.code == 200 &&
          res.data.referralVisitPages.dataList
        ) {
          res.data.referralVisitPages.dataList.map((item) => {
            if (item.visitDate) {
              item.visitDate = format(item.visitDate, "yyyy-MM-dd");
            }
          });
          this.tableData = res.data.referralVisitPages.dataList;
          this.total = res.data.referralVisitPages.maxCount;
          this.page = res.data.referralVisitPages.currentPage;
          this.pageSize = res.data.referralVisitPages.pageSize;
        }
        this.loading = false;
      });
    },
  },
  created() {
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
.ivu-drawer-body {
  div {
    margin-bottom: 14px;
  }
}
/deep/.ivu-modal-close {
  display: none;
}
</style>